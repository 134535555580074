<template>
  <!-- Informations générales communes à toutes les pages -->
  <div class="variables-etudiees-subview">
      <teleport to="#header-action">
        <Btn v-if="helperService.userHasPermission('essaievenement_oedit')" class="action-btn" icon="create"
          color="white" hollow text="Modifier" :to="{
      name: 'essaiExperimentalTaskVariableCalculatedEdit',
      params: {
        id: $route.params.id,
        tid: $route.params.tid
      }
    }" />
      </teleport>

  </div>
  <div class="tabs-material-subtitle no-padding-bottom">
    <Container>
      <h2>Variables calculées</h2>
    </Container>
  </div>
  <div class="variables-etudiees-subview">
    <Section>

      <Container>

<template v-if="calculatedVariables.length > 0">
  <template v-for="(variable, index) in calculatedVariables" :key="index">
    <div class="margin-bottom">
      <div class="display-flex no-margin">
        <div class="padding-right">
          <Checkbox readonly disabled v-model="calculatedVariablesSelected[index].selected" :key="componentKey"
            :id="index.toString()" :items="[{
              label: '',
              value: true,
            }]" />
        </div>
        <div class="flex-grow ">
          <div class="margin-bottom-quarter">
            <h4 class="no-margin">{{ variable?.calcul?.designation }}</h4>
            <div v-if="variable?.cible">Cible: {{ variable?.cible?.valeur }}</div>
            <div><small><b>Variable(s) source:</b> {{ variable.vsources.join(', ') }}</small></div>
          </div>

          <template v-if="calculatedVariablesSelected[index].selected && variable.paramssource.length > 0">
            <div class="margin-bottom-quarter"><b>Paramètre(s)</b></div>
            <div class="grid no-margin">
              <template v-for="(paramssource, j) in variable.paramssource" :key="j">
                <div class="tmp-col">
                  <KeyValue v-if="paramssource.valeurs" :label="paramssource.designation" :value="calculatedVariablesSelected[index].paramssource[j]?.value?.value" />
                  <KeyValue v-else :label="paramssource.designation" :value="calculatedVariablesSelected[index].paramssource[j]?.value" />
                </div>
              </template>
            </div>
          </template>

        </div>
      </div>
    </div>
  </template>

</template>

<template v-else>
  Aucun élément dans cette liste.
</template>
</Container>

    </Section>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import KeyValue from '@/components/form/KeyValue.vue'

export default {
  name: 'VariablesCalculatedSubview',
  components: {
    Btn,
    Section,
    Container,
    Checkbox,
    KeyValue,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isLoading: true,
      fiche: [],
      calculatedVariables: [],
      calculatedVariablesSelected: [],
      componentKey: 0,
    }
  },
  async mounted() {
    await this.getFiche()
  },
  methods: {
    async getFiche() {
      this.emitter.emit('open-loader')
      const response = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`)
      this.fiche = response.data.pop()
      await this.getCalculatedVariables()
      this.emitter.emit('close-loader')
    },
    async getCalculatedVariables() {
      const variableResponse = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.fiche.id}/variable_calculee?limit=0`)
      let localCalculatedVariables = []

      if (variableResponse.data.length > 0) {
        localCalculatedVariables = variableResponse.data.sort(
          (a, b) => a.calcul.designation.localeCompare(b.calcul.designation),
        )

        // eslint-disable-next-line
        for (const cv of localCalculatedVariables) {
          console.log('cv', cv)
          const vsourcesArray = []
          cv.calcul.variables.forEach((vsource) => {
            const index = vsourcesArray.findIndex((item) => (item.id === vsource.id))
            if (index === -1) {
              vsourcesArray.push(vsource.designation)
            }
          })

          // eslint-disable-next-line
          cv.vsources = vsourcesArray
          // eslint-disable-next-line
          const paramsResponse = await this.fetchService.get(`dictionnaire/variable_calculee/${cv.calcul.id}/parametre`)
          // eslint-disable-next-line
          cv.paramssource = []

          // eslint-disable-next-line
          for (const param of paramsResponse.data) {
            // eslint-disable-next-line
            param.value = null

            if (cv.parametres.length > 0) {
              const source = cv.parametres.find((item) => item.id === param.id)
              if (source && source.valeurs === null) {
                param.value = source.pivot.valeur
              }
              if (source && source.valeurs !== null) {
                const valeurs = JSON.parse(source.valeurs)
                const toAdd = valeurs.find((item) => parseInt(item.value, 10) === parseInt(source.pivot.valeur, 10))
                console.log('toAdd', toAdd)
                param.value = { key: toAdd.value, value: toAdd.label }
              }
            }

            cv.paramssource.push(param)
          }

          // eslint-disable-next-line
          cv.displayparams = false

          // eslint-disable-next-line
          cv.paramsoadd = []

          // eslint-disable-next-line
          cv.selected = cv.id ? true : false
        }

        this.calculatedVariables = localCalculatedVariables
        this.calculatedVariablesSelected = localCalculatedVariables
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.display-flex {
  display: flex;
}

.isError {
  color: red
}

.padding {
  padding: 1rem 0;
}

.padding-right {
  padding-right: 2rem;
}
</style>
